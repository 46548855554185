import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint'

export default class DownArrow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      position: 'below',
    }
  }
  handleChange = ({ currentPosition }) => {
    this.setState({
      position: currentPosition,
    })
  }
  render() {
    return (
      <Waypoint
        topOffset="70%"
        bottomOffset="10%"
        onPositionChange={this.handleChange}
      >
        <svg
          viewBox="0 0 15 23"
          className={`c-arrow--down c-arrow--down--${this.state.position}`}
        >
          <title>See more</title>
          <path d="M6.693 20.077V0h1.553v20.137l5.656-5.507L15 15.7 7.5 23 0 15.7l1.098-1.069z" />
        </svg>
      </Waypoint>
    )
  }
}
