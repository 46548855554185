import React from 'react'

export default () => {
  return (
    <svg viewBox="0 0 23 15" className="c-arrow--forward">
      <title>Learn more</title>
      <path d="M20.077 8.307H0V6.754h20.137L14.63 1.098 15.7 0 23 7.5 15.7 15l-1.069-1.098z" />
    </svg>
  )
}
