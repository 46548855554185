import React from 'react'
import { graphql } from 'gatsby'
import {
  Link,
  Helmet,
  useTranslation,
  useI18next,
} from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import PageHeader from '../components/page-header'
import Block from '../components/waypoint-block'
import DetailsAccordion from '../components/details-accordion'
import Drift from '../components/waypoint-drift'
import Card from '../components/card'
import ColorSwitch from '../components/waypoint-color-switch-children'
import SectionHeader from '../components/section-header'
import ForwardArrow from '../components/Icons/ArrowForward'

import DownArrow from '../components/Icons/ArrowDown'

function ThesisPage({ data }) {
  const { language } = useI18next()
  const [t] = useTranslation(['translations', 'seo'])

  const faqs = data.allPrismicFaqs.edges[0].node.data.faq
  const quotes = data.prismicQuotes.data
  const tools = data.prismicThesisPrivateTools.data

  return (
    <React.Fragment>
      <Helmet
        title={t('ThesisPage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('ThesisPage.description', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('ThesisPage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('ThesisPage.ogTitle', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('ThesisPage.ogDescription', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-thesis.jpg',
          },
          { property: 'og:url', content: 'https://versett.com/thesis/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Thesis' },
        ]}
      >
        <html lang={language} className="s-readme" />
      </Helmet>
      <Layout>
        <section className="s-container t-hero s-readme__hero">
          <PageHeader
            lede={t('ThesisPage.Banner.heading')}
            subheading={t('ThesisPage.Banner.subheading')}
          />
          <DownArrow />
        </section>
        <Drift />
        <section className="s-container s-readme__terms">
          {t('ThesisPage.List', { returnObjects: true }).map((term, index) => (
            <Block key={index}>
              <figure className="grid grid--2col-1-2">
                <div />
                <div>
                  <p>{term.name}</p>
                  <h4>{term.heading}</h4>
                  <p className="color-secondary">{term.description}</p>
                </div>
              </figure>
            </Block>
          ))}
        </section>
        <section className="s-section s-section--mobile">
          <Block>
            <section className="s-container">
              <picture className="c-image c-image--fullscreen">
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/team-01-vert.jpg"
                />
                <img
                  src="/images/team-01.jpg"
                  alt="Doug having a quick chat with the group."
                />
              </picture>
            </section>
          </Block>
        </section>
        <section className="s-container s-section">
          <Block>
            <div className="s-readme__bot">
              <h3>{t(`ThesisPage.HowWeWorkSection.heading`)}</h3>
              {t('ThesisPage.HowWeWorkSection.listItems', {
                returnObjects: true,
              }).map((def, index) => (
                <div key={index}>
                  <h4>{def.name}</h4>
                  <p className="color-secondary">{def.description}</p>
                </div>
              ))}
            </div>
          </Block>
        </section>
        <section className="s-container s-section s-readme__antiprocess grid grid--2col-2-1 grid--reverse">
          <Block>
            <div className="s-readme__antiprocess__body">
              <div className="t-section-heading t-section-heading--less-bottom">
                <h2>{t('ThesisPage.HowWeWorkSection.headingTwo')}</h2>
              </div>
              <div className="color-secondary fixed-width">
                <p>{t('ThesisPage.HowWeWorkSection.description')}</p>
              </div>
            </div>
          </Block>
        </section>
        <ColorSwitch>
          <div>
            <section className="s-container s-section">
              <SectionHeader lede={tools.title} />
              <Block>
                <div className="s-readme__tools grid grid--2col-1-1">
                  {tools.private_tool.map((tool, index) => (
                    <Card classes="s-readme__tools__card" key={index}>
                      <div className="color-secondary">
                        {(index + 1).toString().padStart(2, 0)}
                      </div>
                      <h5>{tool.title}</h5>
                      <div
                        dangerouslySetInnerHTML={{ __html: tool.body.html }}
                      />
                      {// Using Link messes with mailto.
                      tool.link_url[0] === '/' ? (
                        <Link to={tool.link_url}>
                          {tool.link_text} <ForwardArrow />
                        </Link>
                      ) : (
                        <a href={tool.link_url}>
                          {tool.link_text} <ForwardArrow />
                        </a>
                      )}
                    </Card>
                  ))}
                </div>
              </Block>
            </section>
            <section className="s-container s-section">
              <SectionHeader lede={quotes.title} />
              <Block>
                <div className="s-readme__quotes grid grid--3col">
                  {quotes.quote.map((quote, index) => (
                    <div className="s-readme__quote" key={index}>
                      <p>
                        <q>{quote.text}</q>
                      </p>
                      <div className="color-secondary">
                        {quote.author}, {quote.company}
                      </div>
                    </div>
                  ))}
                </div>
              </Block>
            </section>
          </div>
        </ColorSwitch>
        <Block>
          <section className="s-container s-section s-readme__faq grid grid--2col-1-2">
            <h2>FAQ</h2>
            <dl>
              {faqs.map((faq, index) => {
                return (
                  <DetailsAccordion
                    key={index}
                    heading={faq.question}
                    open={index === 0}
                  >
                    <div
                      className="color-secondary"
                      dangerouslySetInnerHTML={{ __html: faq.response.html }}
                    />
                  </DetailsAccordion>
                )
              })}
            </dl>
          </section>
        </Block>
        <Prefooter />
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query readmeQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicFaqs(filter: { lang: { eq: $language } }) {
      edges {
        node {
          data {
            faq {
              question
              response {
                html
              }
            }
          }
        }
      }
    }
    prismicQuotes(lang: { eq: $language }) {
      data {
        title
        quote {
          text
          author
          company
        }
      }
    }
    prismicThesisPrivateTools(lang: { eq: $language }) {
      data {
        title
        private_tool {
          title
          body {
            html
          }
          link_text
          link_url
        }
      }
    }
  }
`

export default ThesisPage
